import Vue from "vue"
import { 
    Toast, 
    Button,
    Icon,
    Tabbar, 
    TabbarItem,
    Popup,
    Field,
    NavBar,
    Loading,
    Empty,
    Dialog,
    NoticeBar,
} from 'vant'

Vue.use(Button)
Vue.use(Icon)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Popup)
Vue.use(Field)
Vue.use(NavBar)
Vue.use(Loading)
Vue.use(Empty)
Vue.use(Dialog)
Vue.use(NoticeBar)
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog