import { request } from "@/api/_base";

export const getUserinfo = async () => {
  return await request({
    url: `/api/v1/user/info`,
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const logout = async () => {
  return await request({
    url: `/api/v1/logout`,
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getPayPackages = async () => {
  return await request({
    url: `/api/v1/packages`,
  });
};
