import Vue from "vue";
import {
  Input,
  Button,
  Tooltip,
  Message,
  InputNumber,
  Tabs,
  TabPane,
  Dialog,
  RadioGroup,
  Radio,
  PageHeader,
  Checkbox,
  Badge,
  MessageBox,
  Tag,
  Table,
  TableColumn,
  Loading,
  Popover,
} from "element-ui";

import "@/assets/scss/element.scss";

Vue.use(Input);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(InputNumber);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Dialog);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(PageHeader);
Vue.use(Checkbox);
Vue.use(Badge);
Vue.use(Tag);
Vue.use(Table);
Vue.use(Popover);
Vue.use(TableColumn);
Vue.use(Loading.directive);

Vue.prototype.$messagebox = MessageBox;

Vue.prototype.$message = function(msg) {
  Message({ ...msg, duration: 1000 });
};

Vue.prototype.$message.error = function(msg) {
  return Message.error({
    message: msg,
    duration: 1500,
  });
};

Vue.prototype.$message.success = function(msg) {
  return Message.success({
    message: msg,
    duration: 1500,
  });
};
Vue.prototype.$message.warning = function(msg) {
  return Message.warning({
    message: msg,
    duration: 1500,
  });
};

global.$message = Vue.prototype.$message;
global.$messagebox = MessageBox;
global.$message.error = Vue.prototype.$message.error;
global.$message.success = Vue.prototype.$message.success;
