import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
import store from "../store/index";
import { getUserinfo } from "../api/user";

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/about/agree",
    name: "agree",
    component: () => import("@/views/about/agree.vue"),
  },
  {
    path: "/about/policy",
    name: "policy",
    component: () => import("@/views/about/policy.vue"),
  },
  {
    path: "/main",
    name: "main",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        path: "/",
        redirect: {
          name: "spider",
        },
      },
      {
        path: "spider",
        name: "spider",
        component: () => import("@/views/Spider.vue"),
        meta: {
          requiredAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

const checkIfLogin = async () => {
  if (store.state.userinfo) return true;
  try {
    const res = await getUserinfo();
    if (res && res.code == 0) {
      store.commit("setLogin", true);
      store.commit("setUserinfo", res.data);
      return true;
    } else if (res && res.code == 1001) {
      store.commit("setLogin", false);
      localStorage.setItem("token", "");
      return false;
    }
    return false;
  } catch (err) {
    return false;
  }
};

router.beforeEach((to, from, next) => {
  if (window._hmt) {
    // pv 打点
    if (to.path) {
      window._hmt.push([window.location.pathname + "#" + to.fullPath]);
    }
  }

  checkIfLogin().then((isLogin) => {
    if (to.meta.requiredAuth) {
      if (!isLogin && to.name !== "login") {
        return next({ name: "login" });
      }
      next();
    } else {
      if (isLogin && to.name === "login") {
        return next({ name: "spider" });
      }
      next();
    }
  });
});
export default router;
