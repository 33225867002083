<template>
  <div id="app">
    <router-view :key="$router.currentRoute.fullPath" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      content: "",
      isCopy: false,
      showFooter: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_base.scss";
#app {
  height: 100%;
  background-color: #f5f5fa;
}
</style>
